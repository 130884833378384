import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Labels",
  "description": "All you need to know about label stickers: from it's history, importance, and other special considerations to pay when putting it into use.",
  "author": "Ophelia Zhang",
  "categories": ["shipping-packaging"],
  "date": null,
  "featured": false,
  "tags": ["packaging", "shipping-packaging"],
  "relatedArticles": []
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "what-are-labels"
    }}>{`What are labels?`}</h2>
    <p>{`The first labeling system started in Europe in 1700. It was initially used to name medicines and fabrics. Today, we use labels as an identifier for products. This can be done by placing specific categories or content, keywords, or other tools that you give yourself to act as an identifier.`}</p>
    <p>{`In the printing industry, labels are printed materials used to identify relevant information for your product. Generally, labels have adhesive on its backside to attach to the product. These are also known as "self-adhesive labels".`}</p>
    <blockquote>
      <p parentName="blockquote">{`A label is a piece of paper, plastic film, cloth, metal, or other material attached to a package or product that records information about the product or item concerned.`}</p>
    </blockquote>
    <p>{`Labels have multiple purposes. It shows customers product information such as its logo, usage, shelf life, and other information, such as operating instructions. In many countries/regions, dangerous products such as poisons or flammable liquids must be labeled with warning labels.`}</p>
    <h2 {...{
      "id": "main-label-categories"
    }}>{`Main Label Categories`}</h2>
    <p>{`1`}{`.`}{` `}<strong parentName="p">{`Paper`}</strong>{`: Paper is the main material, basically used for cleaning and daily use;`}</p>
    <p>{`2`}{`.`}{` `}<strong parentName="p">{`Thin films (white, matt, transparent):`}</strong>{` Commonly used PE, PP, PVC and other synthetic materials, thin film materials have low printability, so they are generally treated with corona or enhanced by adding a coating on their surface Printability.`}</p>
    <p>{`3`}{`.`}{` `}<strong parentName="p">{`Multi-color product labels:`}</strong>{` coated paper stickers, mirror coated paper stickers (high gloss), aluminum foil stickers, laser film stickers. It is mostly used in medicine, food and so on.`}</p>
    <p>{`4`}{`.`}{` `}<strong parentName="p">{`Fragile paper self-adhesive label:`}</strong>{` used for anti-counterfeiting seals such as electrical appliances, mobile phones, medicines, food, etc. After peeling off the self-adhesive seal, the label paper will be broken immediately and cannot be reused.`}</p>
    <p>{`5`}{`.`}{` `}<strong parentName="p">{`Thermal paper self-adhesive label`}</strong>{`: suitable for information labels such as price marking and other retail uses.`}</p>
    <p>{`6`}{`.`}{` `}<strong parentName="p">{`PP self-adhesive labels:`}</strong>{` The materials could be either transparent, bright milky white, or matte milky white. They're generally waterproof, oilproof, and chemical resistant. They're often used for hygiene products and cosmetics and are suitable for thermal transfer printing information labels.`}</p>
    <p>{`7`}{`.`}{` `}<strong parentName="p">{`PET (polypropylene) self-adhesive label:`}</strong>{` the material could be transparent, bright gold, bright silver, sub-golden, sub-silver, milky white, or matte milky white. They're also waterproof, oilproof, and non-reactive. PET stickers are often used in product labels with more important properties, such as in hygiene articles, cosmetics, electrical appliances, and mechanical products. It's also suitable for information labels of high-temperature-resistant products.`}</p>
    <p>{`8`}{`.`}{` `}<strong parentName="p">{`PVC self-adhesive label:`}</strong>{` the materials are either transparent, bright milky white, matte milky white, anti-water, oil and chemical substances and other product labels with more important properties, used for toilet products, cosmetics, electrical products, especially suitable for information labels of high-temperature resistant products.`}</p>
    <p>{`9`}{`.`}{` `}<strong parentName="p">{`PVC shrink film self-adhesive label`}</strong>{` is suitable for a special label for battery trademarks.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "376px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "52.50965250965252%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADBklEQVQozyXTXU9TBwDG8fM5FreRAD2ltOelpdD29JTyIuKginS2gKDZpgIug9ExJA5rh04Hqd3ECDgkiDIQaa0Kii8ozAoxU2Fzg8WaxXmxzGQm+wj/Rbj45bl77v6CLGWiSFnIUhaqko2Y8x6tAS+v7w3wfKKXmS93MlzvoD+oc6EpwPXI5zzoC/NkKMqL29P8evEH5ns7uNKxjwvtTQi5pvcxipsQDe+Qnb0Jo5hBTfADlsbDPIw0MNsWIBHaxY2vW1iIdvH47BFepaZ4/STFT5GDLIQ7WRz8nvH2RkZD+xEqtm+l/pM9HGhrofubr5hOjvDPy4c8/3mC+wNhpnsOc/rTPVzqaGaqvYmbx0M8vdjN8rmj/DIQ483deZbOjzASaiYe6USInulnJb3KX+kHvPnzFn//fo30o1HWFs+zujjG6sIYffsCnN29nWRXK93bCkl0BHg5N8y/6WdcOjdEfV0d9YEP+aJxL4LH66KivIiecBt3ps7w7P4Ij66dYmn6FPPxKK9W4lw/0cb4Z3tJDUQZbqziTm8nM4MxKstLMUsSTrcbt7cYp+5FcLisKKqRggKFqm3ldB1sITnWRyo5yNyPJ0ld6Wfl6mnmvjvMcnyI2Z4D/JYYZDlxmSa/H1mRcbt1PN4i3LoHId+hoLlUfJXF7K71obskSks0Pm4IEDsR5lZygj8WZ3g6GWNtdpL0jUn+W3vMi9s3iR85RI1/B5Ki4NYL0dweBJvdjEfPw+croa52B837d1G5xUV1pY7mVAjW7uT4yRiJ0WGuHj3EzLcRLvcc41iolWB5MbrmxGrPw6m5cWk6gqzm4HAolG3W8Bba+ajBT9BfRnWFTvVWN77NBRRpCt6yEmq2eCnLtyCZshANGVjMIjarhD3fti7fYUdQrDlIsgHN9fbURZWvmNIiO1Y1G7tNxG414sgzYRLfxWDIIDf3bQRGVFnEKouoihFFNmK1mcmzKwgmcyYWyYCqiqhWI5b1cjZYzJlIlg2KZFgnWzI3SBsrWbLWqaoJm83C/2wf535n5VzuAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/64e4313a6dc4fa5db3179cd83f8f5c98/652f5/label-04.webp 259w", "/static/64e4313a6dc4fa5db3179cd83f8f5c98/f1608/label-04.webp 376w"],
            "sizes": "(max-width: 376px) 100vw, 376px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/64e4313a6dc4fa5db3179cd83f8f5c98/a2ead/label-04.png 259w", "/static/64e4313a6dc4fa5db3179cd83f8f5c98/d38a6/label-04.png 376w"],
            "sizes": "(max-width: 376px) 100vw, 376px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/64e4313a6dc4fa5db3179cd83f8f5c98/d38a6/label-04.png",
            "alt": "label 04",
            "title": "anti-counterfeit sticker",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h2 {...{
      "id": "importance-of-labels"
    }}>{`Importance of Labels`}</h2>
    <p><strong parentName="p">{`1. Anti-counterfeiting`}</strong>{`:`}<br parentName="p"></br>{`
`}{`Printing and processing on the same label with different processes can allow customers to distinguish the authenticity at a glance, thereby protecting the interests of both buyers and sellers.`}</p>
    <p><strong parentName="p">{`2. Information supplement`}</strong>{`:`}<br parentName="p"></br>{`
`}{`Labels should also display some information, about the products, place, or condition. When you want customers to pay attention to some specific matter, you should display them on your labels.`}</p>
    <h2 {...{
      "id": "labeling-pre-cautions"
    }}>{`Labeling Pre-cautions:`}</h2>
    <ul>
      <li parentName="ul">{`For cylindrical bottles, especially if the diameter is less than 30mm, you should carefully select the appropriate materials for the label stickers.`}</li>
      <li parentName="ul">{`If the label size is too large or too small, some additional processing should be done to make sure the results are satisfactory.`}</li>
      <li parentName="ul">{`If the object to be pasted is an irregular surface or even a spherical surface, you should carefully consider the material, thickness, and adhesion (or glue level) of the label stickers.`}</li>
      <li parentName="ul">{`Certain rough surfaces such as corrugated boxes will be affected by the labels. i.e., certain labels can damage the varnish on the surface of corrugated boxes.`}</li>
      <li parentName="ul">{`If automatic labeling machines are used, you can first conduct a test before carrying out the process.`}</li>
      <li parentName="ul">{`Even if the label is stored at room temperature, re-ensure that it's not exposed to high temperatures during its delivery to the end customers.`}</li>
      <li parentName="ul">{`A humid or oily environment will affect the adhesion of the label stickers.`}</li>
      <li parentName="ul">{`The surface of soft PVC labels can sometimes has plasticizer oozing out, so you should pay extra attention when choosing a suitable adhesive.`}</li>
    </ul>
    <h2 {...{
      "id": "nexpcb-as-your-labeling-partner"
    }}>{`NexPCB as your labeling partner`}</h2>
    <p>{`During your prototyping or development stage, NexPCB can provide you a comprehensive iterative label and packaging development service to design and select the best label stickers for your product. `}</p>
    <p>{`In order to do so, we'll do the following steps:`}</p>
    <ol>
      <li parentName="ol">{`Understand your requirements. `}</li>
      <li parentName="ol">{`Connect to a suitable supplier, and order a testing batch to see if it's up to the standard`}</li>
      <li parentName="ol">{`If there's an error, the last step will be repeated until we get the best result (readjustment and proofing).`}</li>
      <li parentName="ol">{`And we're done!! The labels will be forwarded to the packaging team, where packaging instructions and assembly will be done. `}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      